.page_title {
  margin: 0px;
  font-size: 40px;
  padding: 32px 40px;
  text-align: left;
}

/* Narrow screen style */
@media only screen and (max-width: 800px) {
  .page_title {
    font-size: 5vw;
    padding: 4vw 5vw;
  }
}

/* Very narrow screen style */
@media only screen and (max-width: 600px) {
  .page_title {
    font-size: 30px;
    padding: 24px 30px;
  }
}