#top_nav {
  display: flex;
  height: clamp(300px, calc(166.66vw - 433.33px),400px);
  overflow: hidden;
  background-color: #EEEEEE44;
}

#mobile_menu_section {
  width: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.mobile_menu_btn {
  color: #d9af5fff !important;
  height: calc(100% - 100px);
  background: #845434ee;
  border: 5px solid #d9af5fff;
  font-size: 34px;
  width:40px;
}

.mobile_menu_btn:hover {
  cursor: pointer;
  background-color: #97522dff;
}

/* Narrow conditional styles */
@media only screen and (min-width: 700px) {
  #mobile_menu_section {
    width: 0px;
  }

  .mobile_menu_btn {
    display: none;
  }
}


@media only screen and (max-width: 500px) {
  #mobile_menu_section {
    width: calc(34vw - 110px);
    justify-content: flex-start;
  }

  .mobile_menu_btn {
    height: 300px;
  }
  
}

@media only screen and (max-width: 440px) {
  .mobile_menu_btn {
    height: 100%;
  }

  #mobile_menu_section {
    width: 40px;
    height: calc(75vw - 30px);
  }

  #top_nav {
    height: calc(75vw - 30px);
  }
  
}