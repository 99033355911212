
.page_text {
  margin: 0px;
  font-size: 24px;
  padding: 0px 40px 16px 40px;
  text-align: left;
}

/* Narrow screen style */
@media only screen and (max-width: 800px) {
  .page_text {
    font-size: 3vw;
    padding: 0 5vmin 2vmin 5vmin;
  }
}

/* Very narrow screen style */
@media only screen and (max-width: 600px) {
  .page_text {
    font-size: 18px;
    padding: 0 30px 12px 30px;
  }
}