.App {
  text-align: center;
  overflow: hidden;
  color: black;
  height: 100%;
  min-width: 800px;
  margin: 0px auto;
}

.App a{
  color: black;
  text-decoration: none;
}


/* Mobile/Tablet conditional styles */
@media only screen and (max-width: 800px) {
  .App {
    min-width: 0px;
  }
}

