img {
  height: 100%;
  width: 100%;
}

.icon_link {
  padding: 0px;
  height: clamp(49px, 2.865vw, 54px);
}

a {
  font-size: clamp(25px, 1.5vw, 30px);
  color: #7c3813dc !important;
  padding:10px;
}

a:hover {
  text-decoration: underline;
}

#page_links {
  width: 600px;
  padding-top: 50px;
  display: flex;
  justify-content: space-evenly;
}

/* Desktop conditional styles */
@media only screen and (max-width: 1100px) {
  #page_links {
    width: calc(100vw - 500px);
  }
}

@media only screen and (max-width: 950px) {
  #page_links {
    width: 160px;
    flex-wrap: wrap;
  }
}

/* Mobile/Tablet conditional styles */
@media only screen and (max-width: 700px) {
  /* Responsive template */
  .responsive {
    min-width: 160px !important;
    display: flex !important;
    align-items: center;
    position: absolute;
    top: 50px;
    right: 50px;
    height: 300px;
    padding-top: 0px !important;
    background-color: #EEEEEEEE;
  }

  #page_links{
    display: none;
  }

} 

@media only screen and (max-width: 500px) {
  .responsive {
    top: calc(83.3333vw - 366.6666px);
    right: calc(34vw - 110px);
  }
}

@media only screen and (max-width: 440px) {
  .responsive {
    top: 0;
    right: 40px;
    height: calc(75vw - 30px);
    font:1em;
  }

  .responsive a {
    font-size: clamp(18px, 5.5vw, 25px);
    padding: 2.2vw;
    width: 100%;
  }

  .responsive .icon_link {
    height: clamp(30px, 11vw, 49px);
    padding: 2.2vw 0px;
    width: calc(60px - 2.2vw) !important;
  }
}
