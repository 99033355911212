#logo_section {
  height: clamp(300px, calc(166.66vw - 433.33px),400px);
  width: calc(100% - 600px);
  display: inline-block;
}

#home_logo {
  width: 500px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#home_logo {
  padding: 0px;
}

#Handidowns_logo {
  width: calc(100% - 100px);
  height: calc(100% - 100px);
}

/* Narrow conditional styles */
@media only screen and (max-width: 1100px) {
  #logo_section {
    width: 500px;
  }
}

@media only screen and (max-width: 950px) {
  #logo_section {
    width: calc(100% - 160px);
  }
}

@media only screen and (max-width: 700px) {
  #logo_section {
    width: calc(100% - 60px);
  }
}

@media only screen and (max-width: 500px) {
  #logo_section {
    width: calc(100% - (34vw - 110px));
  }

  #home_logo {
    height: 100%;
    width: 100%;
    justify-content: flex-end;
  }

  #Handidowns_logo {
    width: 400px;
    height: 300px;
  }
}

@media only screen and (max-width: 440px) {
  #logo_section {
    width: calc(100% - 40px);
    height: calc(75vw - 30px);
  }

  #Handidowns_logo {
    width: 100%;
    height: 100%;
  }
  
}

