#copyright_footer {
  padding-left: 40px;
  text-align: left;
}

/* Narrow screen style */
@media only screen and (max-width: 800px) {
  #copyright_footer {
    font-size: 2vw;
    padding: 0 5vmin 2vmin 5vmin;
  }
}

/* Very narrow screen style */
@media only screen and (max-width: 600px) {
  #copyright_footer {
    font-size: 12px;
    padding: 0 30px 12px 30px;
  }
}